import "./style.scss";
import { useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonSpinner } from "@ionic/react";
import { Diagnostic } from "@ionic-native/diagnostic";
import * as shiftApi from "@src/app/api/shift";
import { isValidUUID } from "@src/app/utils/uuid";
import { environmentConfig } from "@src/appV2/environment";
import { CbhFeatureFlag, useCbhFlag, useCbhFlags } from "@src/appV2/FeatureFlags";
import { useToast } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError, logError, logEvent } from "@src/appV2/lib/analytics";
import { getDeviceGeoLocation } from "@src/appV2/Location";
import { UpdateEtaDialog } from "@src/appV2/Shifts/MyShiftUnavailable/lateForShift/UpdateEta/UpdateEtaDialog";
import { Shift as ShiftV2 } from "@src/appV2/Shifts/Shift/types";
import {
  AttendanceConfirmationChannel,
  ConfirmAttendanceResponseCode,
} from "@src/appV2/UrgentShifts/constant";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { USER_EVENTS } from "@src/constants";
import { Shift } from "@src/lib/interface/src";
import { useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useGeolocationTrackingForShiftsEnabled } from "../custom-hooks/useGeolocationTrackingForShiftsEnabled";

const OneTimeConfirmationFlow: FC<{
  shift: Shift;
  onVisibilityChange?: (visibility: boolean) => any;
  geolocationTrackingForShiftsEnabled: boolean;
}> = ({ shift, onVisibilityChange, geolocationTrackingForShiftsEnabled }) => {
  const { showErrorToast } = useToast();

  const history = useHistory();

  const [confirmingAttendance, setConfirmingAttendance] = useState(false);
  const [shouldConfirmAttendance, setShouldConfirmAttendance] = useState(false);

  const checkAttendance = async () => {
    try {
      if (shift._id && isValidUUID(shift._id)) {
        // This means the shift is a synthetic shift, and we don't have to check urgent shifts with synthetic shifts
        return;
      }

      const { attendanceCheck } = await shiftApi.checkAttendance(
        environmentConfig.REACT_APP_URGENT_SHIFTS_SERVICE_URL,
        { shiftId: shift._id }
      );

      setShouldConfirmAttendance(attendanceCheck);
    } catch (err: any) {
      deprecatedDoNotUseLogError({
        message: `Something went wrong while checking attendance ${JSON.stringify(
          err?.stack || err
        )}`,
        metadata: {
          shiftId: shift._id,
          agentId: shift.agentId,
        },
      });
    }
  };

  const confirmAttendance = async () => {
    setConfirmingAttendance(true);
    try {
      let latitude: number | undefined = undefined;
      let longitude: number | undefined = undefined;

      if (geolocationTrackingForShiftsEnabled) {
        const locationAvailable = await Diagnostic.isLocationAvailable();

        if (locationAvailable) {
          const { geoLocation } = await getDeviceGeoLocation();

          ({ latitude, longitude } = geoLocation);
        }
      }

      await shiftApi
        .confirmAttendance(environmentConfig.REACT_APP_URGENT_SHIFTS_SERVICE_URL, {
          shiftId: String(shift._id),
          latitude,
          longitude,
        })
        .then(() => {
          setShouldConfirmAttendance(false);
        });
    } catch (error) {
      const errorMessage = error?.message ?? "Unable to confirm attendance";

      showErrorToast(errorMessage);

      deprecatedDoNotUseLogError({
        message: `Something went wrong while confirming attendance ${JSON.stringify(
          error?.stack || error
        )}`,
        metadata: {
          shiftId: shift._id,
          agentId: shift.agentId,
        },
      });

      if (error?.response?.body?.type === ConfirmAttendanceResponseCode.REPLACEMENT_FOUND) {
        return history.push("/home/myShifts");
      }
    } finally {
      setConfirmingAttendance(false);
    }
  };

  useEffect(() => {
    checkAttendance();
    // FIXME: convert as useQuery and refetch on condition
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shift._id, shift.clockInOut?.start, shift.clockInOut?.isSkipped]);

  useEffect(() => {
    onVisibilityChange?.(shouldConfirmAttendance);
  }, [onVisibilityChange, shouldConfirmAttendance]);

  return shouldConfirmAttendance ? (
    <IonButton
      size="large"
      expand="block"
      shape="round"
      fill="outline"
      onClick={confirmAttendance}
      disabled={confirmingAttendance}
    >
      Confirm Attendance
      {confirmingAttendance && <IonSpinner name="crescent" className="ion-margin-left" />}
    </IonButton>
  ) : null;
};

const LateForShiftFlow: FC<{
  shift: Shift;
  onVisibilityChange?: (visibility: boolean) => any;
}> = ({ shift, onVisibilityChange }) => {
  const { [CbhFeatureFlag.URGENT_SHIFTS_CONFIG]: urgentShiftsConfig = null } = useCbhFlags();
  const maxEtaBoundInMinutes = urgentShiftsConfig?.checkAttendanceBoundsInMinutes?.[1];
  const worker = useDefinedWorker();
  const modalState = useModalState();

  const { data: checkAttendanceData, isSuccess: isCheckAttendanceSuccess } = useQuery(
    ["checkAttendance", shift._id],
    () => {
      return shiftApi.checkAttendanceV1(environmentConfig.REACT_APP_URGENT_SHIFTS_SERVICE_URL, {
        shiftId: shift._id,
        shiftStart: shift.start,
      });
    },
    {
      onError: (error) => {
        logError(`Something went wrong while checking attendance`, {
          error: error,
          metadata: {
            shiftId: shift._id,
            agentId: shift.agentId,
          },
        });
      },
      enabled: isDefined(shift._id),
    }
  );

  const canUpdateEta = isCheckAttendanceSuccess ? checkAttendanceData.attendanceCheck : false;

  useEffect(() => {
    onVisibilityChange?.(canUpdateEta);
  }, [onVisibilityChange, canUpdateEta]);

  return canUpdateEta && !isDefined(shift.clockInOut?.start) && isDefined(maxEtaBoundInMinutes) ? (
    <>
      <IonButton
        size="large"
        expand="block"
        shape="round"
        fill="outline"
        onClick={() => {
          logEvent(USER_EVENTS.OPENED_ETA_MODAL, {
            shiftId: shift._id,
            workerId: worker.userId,
            facilityId: shift?.facility?.userId,
            channel: AttendanceConfirmationChannel.MY_SHIFT_PAGE,
            shiftStart: shift?.start,
            createdAt: new Date().toISOString(),
          });
          modalState.openModal();
        }}
      >
        Late For Shift
      </IonButton>
      {/* FIXME: Mismatch between shift from legacy and appV2 defined Shift objects.
      Change the usages of this component to use appV2 Shift Object. */}
      <UpdateEtaDialog
        modalState={modalState}
        shift={shift as unknown as ShiftV2}
        maxEtaBoundInMinutes={maxEtaBoundInMinutes}
      />
    </>
  ) : null;
};

export const CheckAttendance: FC<{
  shift?: Shift;
  onVisibilityChange?: (visibility: boolean) => any;
}> = ({ shift, onVisibilityChange }) => {
  const ldFlags = useCbhFlags();
  const urgentShiftsEnabled = ldFlags[CbhFeatureFlag.URGENT_SHIFTS] ?? false;
  const isLateForShiftEnabled = useCbhFlag(CbhFeatureFlag.ENABLE_LATE_FOR_SHIFT, {
    defaultValue: false,
  });

  const geolocationTrackingForShiftsEnabled = useGeolocationTrackingForShiftsEnabled(shift);

  if (!shift || !urgentShiftsEnabled) {
    return null;
  }

  return isLateForShiftEnabled ? (
    <LateForShiftFlow shift={shift} onVisibilityChange={onVisibilityChange} />
  ) : (
    <OneTimeConfirmationFlow
      shift={shift}
      onVisibilityChange={onVisibilityChange}
      geolocationTrackingForShiftsEnabled={geolocationTrackingForShiftsEnabled}
    />
  );
};
